:root {
  --primary-color: #0a87ff;
  --secondary-color: #f2581b;
}

body {
  background-color: #f4f9fd;
}

body::-webkit-scrollbar {
  background-color: #424b5438;
  width: 10px;
  border: 1px solid #b5c7d9;
}

body::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 4px;
  background-color: #a9a9a9;
}


/* To prevent changing of background color when selecting browser suggested value in textfield  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}

.checkbox {
  position: relative;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.checkbox__input {
  position: absolute;
  top: -100px;
  left: -100px;
}

.checkbox__inner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #626262;
  background: transparent no-repeat center;
}

.checkbox__input:checked + .checkbox__inner {
  border-color: #52c6c4;
  background-color: #f0fff9;
  /* background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKlHN3mOSsoktrNj1ebLsS-HVNP7UJrVVy954RGR3a7A&s"); */
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2ujiRtubQxD5sDphxpsV63JKD3SAbDc80RA&usqp=CAU");
  background-size: 18px 14px;
}

.step-item {
  @apply relative flex flex-col justify-center items-center w-36;
}

.step-item:not(:first-child):before {
  @apply content-[''] bg-slate-200 absolute w-full h-[3px] right-24 top-4 -translate-y-2/4;
}

/* .step {
  @apply w-10 h-10 flex items-center justify-center z-10 relative bg-slate-700 rounded-full font-semibold text-white;
} */
/* .active .step {
  @apply bg-sky-600;
} */

.complete p {
  @apply text-white;
}

.complete:not(:first-child):before,
.active:not(:first-child):before {
  @apply bg-blue-600;
}

.Rectangle-4109 {
  width: 8px;
  height: 16px;
  margin: 0 6px 0px 0;
  border-radius: 4px;
  background-color: #017eff;
}

fieldset {
  border: 1px solid #ccc;
  border-radius: 2px;
  /* padding: 0 0.625em 0.75em; */
  padding: 0 0.625em 0.15em;
}

.Toastify__toast--error {
  /* border: 1px solid #EB5757; */
  border-radius: 8px !important;
  background: #bc2b2b !important;
  color: #fff !important;
  padding: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 8px !important;
  background: rgb(20, 116, 20) !important;
  color: #fff !important;
  padding: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* .alertProgressBar {
  animation: load 10s ease-in backwards;
  border-radius: 10px;
  background: rgb(215, 203, 203);
  height: 2px;
  position: relative;
  bottom: -13px;
  left: 2px;
}
@keyframes load {
  0% {
    width: 98%;
  }
  100% {
    width: 0%;
  }
} */
/* Hide scrollbar for Chrome, Safari and Opera */
.mobileMenu::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mobileMenu {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

/* pop-up scrollbar styles */
.popup-scroll::-webkit-scrollbar {
  background-color: #dee2e6;
  width: 5px;
  border-radius: 2.5px;
}

.popup-scroll::-webkit-scrollbar-thumb {
  width: 5px;
  height: 148px;
  border-radius: 2.5px;
  background-color: #85a3a9;
}

/* drag drop tile scrollbar */
.campaign-management-scroll::-webkit-scrollbar {
  background-color: #dee2e6;
  width: 5px;
  border-radius: 2.5px;
}

.campaign-management-scroll::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 2.5px;
  background-color: #bdc2c3;
}

/* Common scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(220, 221, 236);
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgb(177, 179, 214);
}
/* scrollbar thickness for firefox */
.custom-scrollbar {
  scrollbar-width: thin;
}

/* scrollbar for customer dashboard */
.customer-scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.customer-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.customer-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(177, 179, 214);
}
.customer-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgb(177, 179, 214);
}
/* scrollbar thickness for firefox */
.customer-scrollbar {
  scrollbar-width: thin;
}

.mobileMenuBlur {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background-color: rgb(0, 0, 0, 0.3);
}

.mobileProposalBlur {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: rgb(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .payment_row {
    justify-content: center;
  }

  .payment_column {
    display: none;
  }

  .payment_column_2 {
    width: 75% !important;
    padding-bottom: 20px;
  }

  .congratulation {
    font-size: 25px !important;
  }

  .payment_component {
    margin-bottom: 100px;
  }
}

.printColor {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.mobilePremium {
  margin: 15px !important;
  /* width: 100% !important;
  max-width: 100% !important; */
}

/* -------- Accordian  --------- */

/* .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0px 5px 0px !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px !important;
} */

.middle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.bar {
  width: 10px;
  height: 70px;
  background: #fff;
  display: inline-block;
  transform-origin: bottom center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  /*   box-shadow:5px 10px 20px inset rgba(255,23,25.2); */
  animation: loader 0.3s linear infinite;
}

.bar1 {
  animation-delay: 0.11s;
}

.bar2 {
  animation-delay: 0.12s;
}

.bar3 {
  animation-delay: 0.13s;
}

.bar4 {
  animation-delay: 0.14s;
}

.bar5 {
  animation-delay: 0.15s;
}

.bar6 {
  animation-delay: 0.16s;
}

.bar7 {
  animation-delay: 0.17s;
}

.bar8 {
  animation-delay: 0.18s;
}

@keyframes loader {
  0% {
    transform: scaleY(0.1);
    background: greenyellow;
  }

  50% {
    transform: scaleY(1);
    background: transparent;
  }

  100% {
    transform: scaleY(0.1);
    background: blue;
  }
}

.listDragDrop {
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  margin: 10px 0px;
  background-color: #f6f6f6;
  border-radius: 50px;
  border: 1px solid #ccc;
  padding: 10px 0px;
  color: #626262;
  font-size: 16px;
  width: 100%;
}

.draggableList {
  text-align: center;
  border-radius: 1rem;
  width: 100%;
  background-color: #f6f6f6;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-width: 0.125rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #626262;
  margin-left: auto;
  margin-right: auto;
  cursor: grab;
  user-select: none;
}

.bgColor {
  background-color: #dadada;
}

.draggableItem {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid #03cc83;
}

.borderList {
  border-width: 0.125rem;
}

.dragableTransition {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  transition-delay: 0.3s;
}

.swal-text {
  display: block;
  text-align: center;
  font-size: 18px;
  color: "#6e8185";
  font-weight: 400;
}

.swal-icon--info {
  border-color: #03cc83;
  color: #03cc83;
}

.swal-button--confirm {
  background-color: #03cc83 !important;
}

/* React Virtualized Table Classes */

/* common row styles */
.table-row {
  border-bottom: 0.1px solid black;
  align-items: center;
  padding: 0px 20px;
  color: black;
  width: 100%;
}
/* header row styles */
.ReactVirtualized__Table__headerRow {
  display: flex;
  background-color: #dbe9ed;
  box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.5);
  border: none;
  font-weight: 500;
  margin-bottom: 5px;
}
/* data row styles */
.ReactVirtualized__Table__row {
  display: flex;
  background-color: white;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  display: none;
}

/* For CK Editor RTE */
.ck.ck-editor__main>.ck-editor__editable{
  min-height: 130px;
}

/* To remove extra eye icon for password, otp & aadhaar number for edge browser */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
@keyframes drop-in {
  from {
    transform: rotate(-30deg) translateY(-100%);
    opacity: 0;
  }
  to {
    transform: rotate(0deg) translateY(0%);
    opacity: 1;
  }
}
@keyframes grow-shrink {
  0% {
    width: 40px;
  }
  100% {
    width: 60px;
  }
}

@keyframes move-in-from-bottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(-100%);
  }
}
