@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Show on large screens */
@media (min-width: 992px) {
  .proposal {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .proposal_heading {
    font-size: 14px;
  }
}

/* Large screen styles */
@media only screen and (min-width: 601px) {
  .proposal_heading {
    font-size: 18px;
  }
}

@media print {
  .printout {
    display: none !important;
  }

  @page {
    size: auto;
    margin: 0mm;
  }
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4f6781;
  color: #fff;
  padding: 6px 12px;
  margin: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  width: 150px;
  white-space: nowrap;
  height: 36px;
}

.custom-file-upload:hover {
  background-color: #4f6781;
}
.listFile {
  display: flex;
  flex-direction: row;
}
.self-inspection {
  height: 50vh;
  width: 100vw;
  object-fit: contain;
}
.user-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.rectangle {
  position: absolute;
  height: 45px;
  width: 8px;
  background: #4398b0;
  top: 0;
  left: 0;
}
.rectangle2 {
  position: absolute;
  height: 92px;
  width: 8px;
  background: #4398b0;
  top: 0;
  left: 0;
}
.selfInspectionLabelText {
  font-size: "12px";
  margin-left: "20px";
}
.label {
  position: absolute;
  height: 17px;
  width: 17px;
  background: #e5ecf0;
  border-radius: 50%;
  top: 15px;
}
.label2 {
  position: absolute;
  height: 17px;
  width: 17px;
  background: #e5ecf0;
  border-radius: 50%;
  top: 15px;
  display: flex;
}
.selfInspectionLabel {
  min-height: 17px;
  min-width: 17px;
  max-height: 17px;
  max-width: 17px;
  background: #e5ecf0;
  border-radius: 50%;
  margin: "auto 0 auto 0";
}
